import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiFailureResponse, ApiResponse } from "models";

export async function apiDo<I = void, O = void>(
  method: "GET" | "POST" | "PUT" | "DELETE",
  url: string,
  data?: I,
): Promise<{ success: true; value: O } | { success: false }> {
  let response: AxiosResponse<ApiResponse<O>>;
  try {
    response = await axios.request({
      url,
      method,
      data: { data },
    });
  } catch (err) {
    console.error(method, url, err);
    if (err instanceof AxiosError<ApiFailureResponse, AxiosRequestConfig>) {
      alert(err.response?.data.error);
    } else alert("unknown error");
    return { success: false };
  }
  if ("error" in response.data) {
    alert(response.data.error);
    return { success: false };
  }
  return { success: true, value: response.data.data };
}

export function apiGet<O = void>(url: string) {
  return apiDo<void, O>("GET", url);
}
export function apiPost<I = void, O = void>(url: string, data: I) {
  return apiDo<I, O>("POST", url, data);
}
export function apiPut<I = void, O = void>(url: string, data: I) {
  return apiDo<I, O>("PUT", url, data);
}
export function apiDelete<O = void>(url: string) {
  return apiDo<void, O>("DELETE", url);
}
