import React from "react";
import { OConsultantWithAppointmentCount } from "models";

const Consultant: React.FC<{ consultant: OConsultantWithAppointmentCount }> = (
  { consultant },
) => {
  const imageSrc = (consultant.info?.image || "").startsWith("/")
    ? "https://artsreach.com" + consultant.info?.image
    : consultant.info?.image;

  return (
    <div
      style={{
        padding: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p>
        <strong>{consultant.info?.title}</strong>
      </p>
      <br />

      <div className="paragraphs">
        <div className="row">
          <div className="span4">
            <img
              src={imageSrc}
              onError={(e) =>
                e.currentTarget.src =
                  "https://via.placeholder.com/200x300?text=Profile+Image"}
              style={{
                float: "left",
                width: "12%",
                marginRight: "20px",
                marginBottom: "20px",
              }}
              alt="profile"
            />
            <div
              dangerouslySetInnerHTML={{
                __html: consultant.info?.description || "",
              }}
            >
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Consultant;
