import React from "react";
import Form from "./Form";
import { ODisplay } from "models";
import { apiGet } from "./api";
import Consultant from "./Consultant";

const App = () => {
  const [display, setDisplay] = React.useState<ODisplay>({
    display: [],
    other: [],
  });

  const getConsultants = React.useCallback(async () => {
    const response = await apiGet<ODisplay>("/api/v1/display");
    if (response.success) {
      setDisplay(response.value);
    }
  }, []);

  React.useEffect(() => {
    getConsultants();
  }, [getConsultants]);

  return (
    <div>
      <br />
      <Form display={display} refresh={getConsultants} />
      <br />
      {display.display.map((d) => {
        return d.consultants.map((c) => {
          return (
            <>
              <div key={c.id} style={{ clear: "both" }}>
                <Consultant consultant={c} />
              </div>
              <br />
            </>
          );
        });
      })}
    </div>
  );
};

export default App;
