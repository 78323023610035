import React from "react";
import { Field, Formik, FormikErrors } from "formik";
import { INewAppointment, OAppointment, ODisplay } from "models";
import { apiPost } from "./api";
import "./default-v4_f1d5918f48.css";
import "./light_256a248f06.css";

const Form: React.FC<{ display: ODisplay; refresh: () => void }> = (
  { display, refresh },
) => {
  const [message, setMessage] = React.useState({ color: "", text: "" });

  const initialValues: INewAppointment = {
    firstName: "",
    lastName: "",
    email: "",
    title: "",
    organization: "",
    phone: "",
    consultant: "",
  };

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      validate={async (values): Promise<FormikErrors<INewAppointment>> => {
        const validated = INewAppointment.validate(values);
        if (validated.success) return {};
        const details = validated.details as FormikErrors<INewAppointment>;
        return details;
      }}
      onSubmit={async (values, actions) => {
        await actions.validateForm(values);
        const response = await apiPost<INewAppointment, OAppointment>(
          "/api/v1/appointments",
          {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            organization: values.organization,
            phone: values.phone,
            title: values.title,
            consultant: values.consultant,
          },
        );
        if (response.success) {
          if (response.value.rejected) {
            setMessage({
              color: "red",
              text:
                "Your request has been rejected, please reach out to ceo@artsreach.com for further details",
            });
            actions.resetForm();
            refresh();
          } else {
            setMessage({
              color: "green",
              text:
                "Thank you for requesting your free consultation. We will notify your consultant of your request and they will be in touch with you soon about how to proceed.",
            });
            actions.resetForm();
            refresh();
          }
        }
      }}
    >
      {(props) => {
        return (
          <div className="fsSection fs1Col">
            <form
              className="fsForm fsSingleColumn"
              onSubmit={props.handleSubmit}
            >
              {Object.keys(props.errors).length > 0
                ? (
                  <div className="fsError">
                    <>
                      Please fix the following errors:
                      <br />
                      {Object.values(props.errors).map((v) => {
                        return <div>- {v}</div>;
                      })}
                    </>
                  </div>
                )
                : <></>}
              <div className="fsRow fsFieldRow fsLastRow">
                <div
                  className={`fsRowBody fsCell fsFieldCell fsFirst fsLast fsLabelVertical fsSpan100 ${
                    props.touched.firstName &&
                      props.touched.lastName &&
                      (props.errors.firstName || props.errors.lastName)
                      ? "fsValidationError"
                      : ""
                  }
`}
                >
                  <span className="fsLabel fsRequiredLabel">Name</span>
                  <span className="fsRequiredMarker">*</span>
                  <div
                    className="fsSubFieldGroup"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="fsSubField fsNameFirst">
                      <input
                        className="fsField fsFieldName fsRequired"
                        type="text"
                        id="firstName"
                        {...props.getFieldProps("firstName")}
                      />
                      <label
                        className="fsSupporting fsRequiredLabel"
                        htmlFor="firstName"
                      >
                        First Name
                      </label>
                    </div>
                    <div className="fsSubField fsNameLast">
                      <input
                        className="fsField fsFieldName fsRequired"
                        type="text"
                        id="lastName"
                        {...props.getFieldProps("lastName")}
                      />
                      <label
                        className="fsSupporting fsRequiredLabel"
                        htmlFor="lastName"
                      >
                        Last Name
                      </label>
                    </div>
                  </div>
                  <div className="clear"></div>
                </div>
              </div>
              <br />
              <div className="fsRow fsFieldRow fsLastRow">
                <div
                  className={`fsRowBody fsCell fsFieldCell fsFirst fsLast fsLabelVertical fsSpan100 ${
                    props.touched.title && props.errors.title
                      ? "fsValidationError"
                      : ""
                  }`}
                >
                  <span className="fsLabel fsRequiredLabel">Title</span>
                  <span className="fsRequiredMarker">*</span>
                  <input
                    id="title"
                    type="text"
                    {...props.getFieldProps("title")}
                  />
                  <div className="clear"></div>
                </div>
              </div>
              <br />
              <div className="fsRow fsFieldRow fsLastRow">
                <div
                  className={`fsRowBody fsCell fsFieldCell fsFirst fsLast fsLabelVertical fsSpan100 ${
                    props.touched.organization && props.errors.organization
                      ? "fsValidationError"
                      : ""
                  }`}
                >
                  <span className="fsLabel fsRequiredLabel">Organization</span>
                  <span className="fsRequiredMarker">*</span>
                  <input
                    id="organization"
                    type="text"
                    {...props.getFieldProps("organization")}
                  />
                  <div className="clear"></div>
                </div>
              </div>
              <br />
              <div className="fsRow fsFieldRow fsLastRow">
                <div
                  className={`fsRowBody fsCell fsFieldCell fsFirst fsLast fsLabelVertical fsSpan100 ${
                    props.touched.email && props.errors.email
                      ? "fsValidationError"
                      : ""
                  }`}
                >
                  <span className="fsLabel fsRequiredLabel">Email</span>
                  <span className="fsRequiredMarker">*</span>
                  <input
                    id="email"
                    type="text"
                    {...props.getFieldProps("email")}
                  />
                  <div className="clear"></div>
                </div>
              </div>
              <br />
              <div className="fsRow fsFieldRow fsLastRow">
                <div
                  className={`fsRowBody fsCell fsFieldCell fsFirst fsLast fsLabelVertical fsSpan100 ${
                    props.touched.phone && props.errors.phone
                      ? "fsValidationError"
                      : ""
                  }`}
                >
                  <span className="fsLabel fsRequiredLabel">Phone</span>
                  <span className="fsRequiredMarker">*</span>
                  <input
                    id="phone"
                    type="text"
                    {...props.getFieldProps("phone")}
                  />
                  <div className="clear"></div>
                </div>
              </div>
              <br />
              <div className="fsRow fsFieldRow fsLastRow">
                <div
                  className={`fsRowBody fsCell fsFieldCell fsFirst fsLast fsLabelVertical fsSpan100 fsFieldFocused ${
                    props.touched.consultant && props.errors.consultant
                      ? "fsValidationError"
                      : ""
                  }`}
                >
                  <legend className="fsLabel fsRequiredLabel fsLabelVertical">
                    <span>Select Consultant</span>
                    <span className="fsRequiredMarker">*</span>
                  </legend>
                  <div className="fieldset-content">
                    {display.display.map((d) => {
                      return (
                        <>
                          <div key={d.group.id}>
                            <div>
                              <strong>{d.group.name}</strong>
                            </div>
                            {d.consultants
                              .map((c) => {
                                const available =
                                  c.numOfAppointments < (c.maxAppt || 0);
                                return (
                                  <Field name={c.name} key={c.id}>
                                    {/* TODO(jared): don't do this */}
                                    {/* @ts-ignore */}
                                    {({ form }) => {
                                      return (
                                        <div>
                                          <label
                                            className="fsOptionLabel vertical"
                                            style={{
                                              ...(!available
                                                ? { color: "lightgrey" }
                                                : {}),
                                            }}
                                          >
                                            <input
                                              id={c.id}
                                              name="consultant"
                                              value={c.id}
                                              type="checkbox"
                                              className="fsField fsRequired vertical"
                                              checked={form.values
                                                .consultant === c.id}
                                              disabled={!available}
                                              onChange={() => {
                                                const copy = props.errors;
                                                delete copy.consultant;
                                                props.setErrors(copy);
                                                form.setFieldValue(
                                                  "consultant",
                                                  form.values.consultant ===
                                                      c.id
                                                    ? ""
                                                    : c.id,
                                                );
                                              }}
                                            />
                                            {`${c.info
                                              ?.title} with ${c.organization}${
                                              !available
                                                ? " (will be available again next month)"
                                                : ""
                                            }`}
                                          </label>
                                        </div>
                                      );
                                    }}
                                  </Field>
                                );
                              })}
                          </div>
                          <br />
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="fsSubmit fsPagination">
                <input
                  className="fsSubmitButton"
                  type="submit"
                  value="Submit Form"
                />
                {message.text
                  ? <span style={{ color: message.color }}>{message.text}</span>
                  : null}
              </div>
              <div className="clear"></div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default Form;
